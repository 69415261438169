/*************************************************
 * HotDeck
 * @exports
 * Login.tsx
 * Created by venugopal on 14/04/2024
 * Copyright © 2024 HotDeck. All rights reserved.
 *************************************************/

import { useEffect, useState } from "react"; // Import React and necessary hooks
import { useIsAuthenticated, useMsal } from "@azure/msal-react"; // Import authentication hooks from MSAL
import { loginRequest } from '../../authConfig'; // Import login request configuration
import Loading from "../custom/Loading"; // Import Loading component

// Define Login functional component
const Login = () => {

  // Initialize state variables
  const isAuthenticated = useIsAuthenticated(); // Check if user is authenticated
  const { instance, accounts } = useMsal(); // Get MSAL instance and user accounts
  const [isLoading, setIsLoading] = useState(true); // Track loading state

  // Function to request and store profile data
  function RequestProfileData() {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then(async (response) => {
        await localStorage.setItem("ACCESS_TOKEN", response.idToken); // Store access token in local storage

      })
      .finally(() => {
        setIsLoading(false); // Set loading state to false when authentication is complete
      });
  }

  // Effect hook to trigger authentication process when authentication status changes
  useEffect(() => {
    if (isAuthenticated) {
      RequestProfileData(); // Request profile data if user is authenticated
    } else {
      localStorage.removeItem("ACCESS_TOKEN"); // Remove access token from local storage if not authenticated
      localStorage.removeItem("REFRESH_TOKEN"); // Remove refresh token from local storage if not authenticated
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  // Effect hook to handle initial authentication process
  useEffect(() => {
    if (!isAuthenticated) {
      instance.loginRedirect(loginRequest).catch(() => { }); // Redirect user to login if not authenticated
    } else {
      setIsLoading(false); // Set loading state to false if already authenticated
    }
  }, [isAuthenticated, instance]);

  // Render Loading component if authentication process is ongoing
  return (
    <>
      {isLoading ?
        <Loading />
        : null}
    </>
  );
}

export default Login; // Export Login component
