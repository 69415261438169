/*************************************************
 * HOT DECK
 * @exports
 * index.ts
 * Created by venugopal on 05/04/2024
 * Copyright © 2023 HotDeck. All rights reserved.
 *************************************************/

// Import necessary modules
import { createApi } from "@reduxjs/toolkit/query/react";
import CustomFetchBase from "./CustomFetchBase";
// Create an API using Redux Toolkit's mapChallengeApi function
export const mapChallengeApi = createApi({
  // Specify a reducer path for the API
  reducerPath: "mapChallengeApi",
  // Define a base query using CustomFetchBase
  baseQuery: CustomFetchBase,
  // Define endpoints for the API
  endpoints: (builder) => ({
    getChallengesByConditions: builder.mutation({
      query: (body) => ({
        url: `/deckcreation/getChallengesByConditions`,
        method: "POST",
        body: body,
      }),
    }),
    getCards: builder.mutation({
      query: (body) => ({
        url: `/deckcreation/getCards`,
        method: "POST",
        body: body,
      }),
    }),

    getSuits: builder.query({
      // Specify the query function
      query: ({ deckId = "deckId" }) => ({
        // Specify the URL for the request
        url: `/deckcreation/getSuits?deckId=${deckId}`,
        // Specify the HTTP method for the request
        method: "GET",
      }),
    }),

    mapChallengesToDeck: builder.mutation({
      query: (body) => ({
        url: `/deckcreation/mapChallengesToDeck`,
        method: "POST",
        body: body,
      }),
    }),

    lockDeckById: builder.query({
      // Specify the query function
      query: ({ id = "id" }) => ({
        // Specify the URL for the request
        url: `/deckcreation/lockDeckById?id=${id}`,
        // Specify the HTTP method for the request
        method: "GET",
      }),
    }),
    
  }),
});

// Export the loginUser query hook from mapChallengeApi
export const {
  useGetChallengesByConditionsMutation,
  useGetCardsMutation,
  useLazyGetSuitsQuery,
  useMapChallengesToDeckMutation,
  useLazyLockDeckByIdQuery
} = mapChallengeApi;
