/*************************************************
 * HOT DECK
 * @exports
 * DeckApi.ts
 * Created by venugopal on 05/04/2024
 * Copyright © 2023 HotDeck. All rights reserved.
 *************************************************/

// Import necessary modules
import { createApi } from "@reduxjs/toolkit/query/react";
import CustomFetchBase from "./CustomFetchBase";
// Create an API using Redux Toolkit's deckApi function
export const deckApi = createApi({
  // Specify a reducer path for the API
  reducerPath: "deckApi",
  // Define a base query using CustomFetchBase
  baseQuery: CustomFetchBase,
  // Define endpoints for the API
  endpoints: (builder) => ({
    getDecks: builder.mutation({
      query: (body) => ({
        url: `/deckcreation/getDecks`,
        method: "POST",
        body: body,
      }),
    }),
    addDecks: builder.mutation({
      query: (body) => ({
        url: `/deckcreation/addDeck`,
        method: "POST",
        body: body,
      }),
    }),
    getAllThemes: builder.mutation({
      query: (body) => ({
        url: `/deckcreation/getAllThemes`,
        method: "POST",
        body: body,
      }),
    }),
    getAllTags: builder.mutation({
      query: (body) => ({
        url: `/deckcreation/getAllTags`,
        method: "POST",
        body: body,
      }),
    }),
    getDeckById: builder.query({
      // Specify the query function
      query: ({ id = "id" }) => ({
        // Specify the URL for the request
        url: `/deckcreation/getDeckById?id=${id}`,
        // Specify the HTTP method for the request
        method: "GET",
      }),
    }),

    editDeck: builder.mutation({
      query: (body) => ({
        url: `/deckcreation/updateDeck`,
        method: "POST",
        body: body,
      }),
    }),
    cloneDeck: builder.mutation({
      query: (body) => ({
        url: `/deckcreation/cloneDeck`,
        method: "POST",
        body: body,
      }),
    }),
  }),
});

// Export the loginUser query hook from deckApi
export const {
  useGetDecksMutation,
  useAddDecksMutation,
  useGetAllThemesMutation,
  useGetAllTagsMutation,
  useLazyGetDeckByIdQuery,
  useEditDeckMutation,
  useCloneDeckMutation
} = deckApi;
