/*************************************************
 * HOT DECK
 * @exports
 * index.ts
 * Created by venugopal on 05/04/2024
 * Copyright © 2023 HotDeck. All rights reserved.
 *************************************************/

// Import necessary modules
import { createApi } from "@reduxjs/toolkit/query/react";
import CustomFetchBase from "./CustomFetchBase";
// Create an API using Redux Toolkit's commonApi function
export const commonApi = createApi({
  // Specify a reducer path for the API
  reducerPath: "commonApi",
  // Define a base query using CustomFetchBase
  baseQuery: CustomFetchBase,
  // Define endpoints for the API
  endpoints: (builder) => ({
    getSASToken: builder.mutation({
      query: (body) => ({
        url: `/user/getSASToken`,
        method: "POST",
        body: body,
      }),
    }),
  }),
});

// Export the loginUser query hook from commonApi
export const { useGetSASTokenMutation } = commonApi;
