/*************************************************
 * HOT DECK
 * @exports
 * index.ts
 * Created by venugopal on 05/04/2024
 * Copyright © 2023 HotDeck. All rights reserved.
 *************************************************/

// Import necessary modules
import { createApi } from "@reduxjs/toolkit/query/react";
import CustomFetchBase from "./CustomFetchBase";
// Create an API using Redux Toolkit's themeApi function
export const themeApi = createApi({
  // Specify a reducer path for the API
  reducerPath: "themeApi",
  // Define a base query using CustomFetchBase
  baseQuery: CustomFetchBase,
  // Define endpoints for the API
  endpoints: (builder) => ({
    getThemes: builder.mutation({
      query: (body) => ({
        url: `/deckcreation/getThemes`,
        method: "POST",
        body: body,
      }),
    }),

    addTheme: builder.mutation({
      query: (body) => ({
        url: `/deckcreation/addTheme`,
        method: "POST",
        body: body,
      }),
    }),
    getThemeById: builder.query({
      // Specify the query function
      query: ({ id = "id" }) => ({
        // Specify the URL for the request
        url: `/deckcreation/getThemeById?id=${id}`,
        // Specify the HTTP method for the request
        method: "GET",
      }),
    }),
    editTheme: builder.mutation({
      query: (body) => ({
        url: `/deckcreation/updateTheme`,
        method: "POST",
        body: body,
      }),
    }),
    getAssociatedDecks: builder.mutation({
      query: (body) => ({
        url: `/deckcreation/getDecks`,
        method: "POST",
        body: body,
      }),
    }),

    getAllThemes: builder.mutation({
      query: (body) => ({
        url: `/deckcreation/getAllThemes`,
        method: "POST",
        body: body,
      }),
    }),
  }),
});

// Export the loginUser query hook from themeApi
export const {
  useGetThemesMutation,
  useAddThemeMutation,
  useLazyGetThemeByIdQuery,
  useEditThemeMutation,
  useGetAssociatedDecksMutation,
  useGetAllThemesMutation
} = themeApi;
