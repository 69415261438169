/*************************************************
 * HOT DECK
 * @exports
 * Store.ts
 * Created by venugopal on 05/04/2024
 * Copyright © 2024 HotDeck. All rights reserved.
 *************************************************/

// Import necessary modules and functions
import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../redux/slice/userSlice"; // Import user slice

import { authApi } from "./services/AuthApi";
import { roleApi } from "./services/RoleApi";
import { profileApi } from "./services/ProfileApi";
import { userApi } from "./services/UserApi";
import { themeApi } from "./services/ThemeApi";
import { commonApi } from "./services/CommonApi";
import { deckApi } from "./services/DeckApi";
import { mapChallengeApi } from "./services/MapChallengeApi";
import { generateURLApi } from "./services/GenerateURLApi";
import { challengesApi } from "./services/ChallengesApi";
import { customerApi } from "./services/CustomerApi";
import { DashboardApi } from "./services/DashboardApi";
// Configure the Redux store
export const Store = configureStore({
  // Define reducers for the store
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [roleApi.reducerPath]: roleApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [themeApi.reducerPath]: themeApi.reducer,
    [commonApi.reducerPath]: commonApi.reducer,
    [deckApi.reducerPath]: deckApi.reducer,
    [mapChallengeApi.reducerPath]: mapChallengeApi.reducer,
    [challengesApi.reducerPath]: challengesApi.reducer,
    [generateURLApi.reducerPath]: generateURLApi.reducer,
    [customerApi.reducerPath]: customerApi.reducer,
    [DashboardApi.reducerPath]: DashboardApi.reducer,

    userState: userReducer, // Include user reducer
  },
  // Enable Redux DevTools extension only in development mode
  devTools: process.env.NODE_ENV === "development",
  // Add middleware functions for handling asynchronous actions
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([
      authApi.middleware,
      roleApi.middleware,
      profileApi.middleware,
      userApi.middleware,
      themeApi.middleware,
      commonApi.middleware,
      deckApi.middleware,
      mapChallengeApi.middleware,
      challengesApi.middleware,
      generateURLApi.middleware,
      customerApi.middleware,
      DashboardApi.middleware,
    ]),
});

// Define type for the root state of the store
export type RootState = ReturnType<typeof Store.getState>;
// Define type for the dispatch function of the store
export type AppDispatch = typeof Store.dispatch;
