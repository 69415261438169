export const CRUD_ACTIONS = {
    UPDATE: 'update',
    CREATE: 'create',
    READ: 'list',
    ROOT_FOLDER: 1
};

export const MODULE_NAME = {
    CHALLENGES: 'Challenges',
    CUSTOMERS: 'Customers',
    DASHBOARD: 'Dashboard',
    DECKS: 'Decks',
    ROLEANDPERMISSIONS: 'Roles and Permissions',
    THEMES: 'Themes',
    USERMANAGEMENT: 'Admin Users Management'
};
export const PERMISSION = {
    ENABLE_ACCESS: 1,
    DISABLE_ACCESS: 0
}

export const formatTimeInput = (value: any) => {
    // Remove any non-digit characters
    const cleanedValue = value.replace(/\D/g, '');
    if (cleanedValue.length < 6) {
      return value;
    }
  
    const days = cleanedValue.slice(0, 2);
    const hours = cleanedValue.slice(2, 4);
    const minutes = cleanedValue.slice(4, 6);
    return `${days}:${hours}:${minutes}`;
  };
