import { createContext, useState } from 'react';

interface UserProfile {
  profileImageUrl: string;
  name: string;
}
interface UserProfileContextValue {
  userProfile: UserProfile;
  setUserProfile: (userProfile: UserProfile) => void;
}
const UserProfileContext = createContext<UserProfileContextValue>({
  userProfile: {
    profileImageUrl: '',
    name: '',
  },
  setUserProfile: () => {},
});

const UserProfileProvider = ({ children }:any) => {
  const [userProfile, setUserProfile] = useState<UserProfile>({
    profileImageUrl: '',
    name: '',
  });

  return (
    <UserProfileContext.Provider value={{ userProfile, setUserProfile }}>
      {children}
    </UserProfileContext.Provider>
  );
};

export { UserProfileProvider, UserProfileContext };