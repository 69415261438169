/*************************************************
 * HotDeck
 * @exports
 * Sidebar.tsx
 * Created by venugopal on 14/04/2024
 * Copyright © 2024 HotDeck. All rights reserved.
 *************************************************/

// Import
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import sidebarlogo from "../../../assets/sidelogo.png";
import { Icon } from "@iconify/react";
import { useGetPagesMutation } from "../../../redux/services/RoleApi";

// Define the type for menu items
interface Permission {
  Create: number;
  View: number;
  Edit: number;
  Delete: number;
}

type MenuItem = {
  moduleName: string;
  pathUrl: string;
  iconUrl: string;
  pageName: string;
  permissions: Permission;
};

const SideBar: React.FC = () => {
  const location = useLocation().pathname;

  const [pages, setPages] = useState<MenuItem[]>([]);
  const [getPages, { isLoading, isError, isSuccess, data }] =
    useGetPagesMutation();

  useEffect(() => {
    const storedPages = localStorage.getItem("pages");
    if (storedPages) {
      setPages(JSON.parse(storedPages));
    } else {
      getPages("");
    }
  }, [getPages]);

  useEffect(() => {
    if (isSuccess && data) {
      setPages(data.data);
      localStorage.setItem("pages", JSON.stringify(data.data));
    }
  }, [isSuccess, data]);

  return (
    <div className="flex-shrink-0 hidden h-screen bg-white border-r-2 shadow-md md:block">
      <div className="flex flex-col h-full max-h-screen gap-2">
        <div className="flex h-14 items-center mt-10 justify-center px-4 lg:h-[60px] lg:px-6">
          <img src={sidebarlogo} alt="sidebar logo" />
        </div>
        <div className="flex px-2 mt-8">
          {isLoading && <p>Loading...</p>}
          {isError && <p>Failed to load pages</p>}
          <nav className="grid items-start space-y-4 text-sm font-medium lg:px-4 text-nowrap">
            {pages.length > 0 &&
              pages.map((menuItem: MenuItem, index: number) =>
                menuItem.permissions.View === 1 ? (
                  <Link
                    key={index}
                    to={menuItem.pathUrl}
                    className={`flex items-center gap-3 py-2 px-4 transition-all rounded-lg ${
                      location.includes(menuItem.pathUrl)
                        ? "bg-muted font-semibold text-primary"
                        : "text-[#9A9A97] text-bold font-semibold text-sm hover:text-primary"
                    }`}
                  >
                    <Icon
                      icon={menuItem.iconUrl}
                      className="text-[#9A9A97]"
                      style={{ width: "18px", height: "18px" }}
                    />
                    <span>{menuItem.moduleName}</span>
                  </Link>
                ) : null
              )}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
