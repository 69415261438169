/*************************************************
 * HOT DECK
 * @exports
 * GenerateURLApi.ts
 * Created by santhosh on 04/06/2024
 * Copyright © 2023 HotDeck. All rights reserved.
 *************************************************/
 
// Import necessary modules
import { createApi } from "@reduxjs/toolkit/query/react";
import CustomFetchBase from "./CustomFetchBase";
// Create an API using Redux Toolkit's GenerateURLApi function
export const generateURLApi = createApi({
  // Specify a reducer path for the API
  reducerPath: "generateURLApi",
  // Define a base query using CustomFetchBase
  baseQuery: CustomFetchBase,
  // Define endpoints for the API
  endpoints: (builder) => ({
    getPrintedDecks: builder.mutation({
      query: (body) => ({
        url: `/deckcreation/getPrintedDecks`,
        method: "POST",
        body: body,
      }),
    }),
    printDeckId: builder.mutation({
      query: (body) => ({
        url: `/deckcreation/printDeckById`,
        method: "POST",
        body: body,
      }),
    }),
    exportPrintedDecks: builder.mutation({
      query: (body) => ({
        url: `/deckcreation/exportPrintedDecks`,
        method: "POST",
        body: body,
      }),
    }),
  }),
});
 
// Export the loginUser query hook from generateURL api
export const { useGetPrintedDecksMutation, usePrintDeckIdMutation,useExportPrintedDecksMutation } =
  generateURLApi;
 