/*************************************************
 * HOT DECK
 * @exports
 * index.ts
 * Created by venugopal on 05/04/2024
 * Copyright © 2023 HotDeck. All rights reserved.
 *************************************************/

// Import necessary modules
import { createApi } from "@reduxjs/toolkit/query/react";
import CustomFetchBase from "./CustomFetchBase";
// Create an API using Redux Toolkit's createApi function
export const userApi = createApi({
  // Specify a reducer path for the API
  reducerPath: "userApi",
  // Define a base query using CustomFetchBase
  baseQuery: CustomFetchBase,
  // Define endpoints for the API
  endpoints: (builder) => ({
    addUser: builder.mutation({
      query: (body) => ({
        url: `/user/addUser`,
        method: "POST",
        body: body,
      }),
    }),
    getUsers: builder.mutation({
      query: (body) => ({
        url: `/user/getUsers`,
        method: "POST",
        body: body,
      }),
    }),
    getUserById: builder.query({
      // Specify the query function
      query: ({ id = "id" }) => ({
        // Specify the URL for the request
        url: `/user/getUserById?id=${id}`,
        // Specify the HTTP method for the request
        method: "GET",
      }),
    }),
    updateUser: builder.mutation({
      query: (body) => ({
        url: `/user/updateUser`,
        method: "POST",
        body: body,
      }),
    }),
  }),
});

// Export the loginUser query hook from userApi
export const {
  useAddUserMutation,
  useGetUsersMutation,
  useLazyGetUserByIdQuery,
  useUpdateUserMutation,
} = userApi;
