/*************************************************
 * HOT DECK
 * @exports
 * index.tsx
 * Created by venugopal on 05/04/2024
 * Copyright © 2024 HotDeck. All rights reserved.
 *************************************************/

// Import React and necessary components
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Store } from "./redux/Store"; // Import Redux store
import reportWebVitals from "./reportWebVitals"; // Import web vitals reporting utility

import "./index.css"; // Import global CSS styles
import "react-toastify/dist/ReactToastify.css"; // Import Toastify CSS
import { Configuration, PublicClientApplication } from "@azure/msal-browser"; // Import MSAL components
import { msalConfig } from "./authConfig"; // Import MSAL configuration
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react"; // Import MSAL React components
import { RouterProvider } from "react-router-dom"; // Import BrowserRouter for routing
import Login from "./components/pages/Login"; // Import Login component
import { Toaster } from "./components/ui/toaster";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { router } from "./routes";
import Loading from "./components/custom/Loading";

// Create MSAL instance with provided configuration
export const msalInstance = new PublicClientApplication(
  msalConfig as Configuration
);

// Get the root container from the DOM
const container = document.getElementById("root")!;
const root = createRoot(container);

const queryClient = new QueryClient();
// Render the application
root.render(
  // Provide MSAL context to the application
  <MsalProvider instance={msalInstance}>
    {/* Provide Redux store to the application */}
    <Provider store={Store}>
      {/* Conditionally render App component if user is authenticated */}
      <AuthenticatedTemplate>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} fallbackElement={<Loading/>}/>
        </QueryClientProvider>
      </AuthenticatedTemplate>
      {/* Conditionally render Login component if user is not authenticated */}
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
      <Toaster />
    </Provider>
  </MsalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(); // Report web vitals for performance measurement
