/*************************************************
 * HOT DECK
 * @exports
 * index.ts
 * Created by venugopal on 05/04/2024
 * Copyright © 2023 HotDeck. All rights reserved.
 *************************************************/

// Import necessary modules
import { createApi } from "@reduxjs/toolkit/query/react";
import CustomFetchBase from "./CustomFetchBase";
import { setUser } from "../slice/userSlice";

// Create an API using Redux Toolkit's createApi function
export const authApi = createApi({
  // Specify a reducer path for the API
  reducerPath: "authApi",
  // Define a base query using CustomFetchBase
  baseQuery: CustomFetchBase,
  // Define endpoints for the API
  endpoints: (builder) => ({
    // Define the loginUser endpoint as a query
    loginUser: builder.query({
      // Specify the query function
      query: () => ({
        // Specify the URL for the request
        url: `/user/auth`,
        // Specify the HTTP method for the request
        method: "GET",
      }),
      // Define an onQueryStarted callback (currently commented out)
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data.data));
        } catch (error) {}
      },
    }),
    changeStatus: builder.query({
      // Specify the query function
      query: () => ({
        // Specify the URL for the request
        url: `/user/changeStatus?status=1`,
        // Specify the HTTP method for the request
        method: "GET",
      }),
      // Define an onQueryStarted callback (currently commented out)
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data.data));
        } catch (error) {}
      },
    }),
  }),
});

// Export the loginUser query hook from authApi
export const { useLazyLoginUserQuery,useLazyChangeStatusQuery } = authApi;
