/*************************************************
 * HOT DECK
 * @exports
 * CustomerApi.ts
 * Created by santhosh on 22/07/2024
 * Copyright © 2023 HotDeck. All rights reserved.
 *************************************************/

// Import necessary modules
import { createApi } from "@reduxjs/toolkit/query/react";
import CustomFetchBase from "./CustomFetchBase";
export const customerApi = createApi({
  reducerPath: "customerApi",
  baseQuery: CustomFetchBase,
  endpoints: (builder) => ({
    getCustomers: builder.mutation({
      query: (body) => ({
        url: `/user/getPlayers`,
        method: "POST",
        body: body,
      }),
    }),
    getAssociatedDecks: builder.mutation({
      query: (body) => ({
        url: `/user/getAssociatedDeckByPlayerId`,
        method: "POST",
        body: body,
      }),
    }),
    getReferredPlayerList: builder.mutation({
      query: (body) => ({
        url: `/user/getReferralListById`,
        method: "POST",
        body: body,
      }),
    }),
    blockAssociatedDecks: builder.mutation({
      query: (body) => ({
        url: `/user/blockAssociatedDeck`,
        method: "POST",
        body: body,
      }),
    }),
    exportUsers: builder.mutation({
      query: () => ({
        url: `/user/exportPlayers`,
        method: "GET",
      }),
    }),
  }),
});
export const {
  useGetCustomersMutation,
  useGetAssociatedDecksMutation,
  useGetReferredPlayerListMutation,
  useBlockAssociatedDecksMutation,
  useExportUsersMutation
} = customerApi;
