/*************************************************
 * HOT DECK
 * @exports
 * UserSlice.ts
 * Created by venugopal on 05/04/2024
 * Copyright © 2024 HotDeck. All rights reserved.
 *************************************************/

// Importing necessary modules
import { createSlice } from "@reduxjs/toolkit";

// Initial state for the user slice, fetched from localStorage if available
const initialState = {
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user") || "")
    : null,
  role: localStorage.getItem("role")
    ? JSON.parse(localStorage.getItem("role") || "")
    : null,
  pages: localStorage.getItem("pages")
    ? JSON.parse(localStorage.getItem("pages") || "")
    : null,
};

// Creating a Redux slice for managing user state
export const userSlice = createSlice({
  initialState, // Initial state of the slice
  name: "user", // Name of the slice
  reducers: {
    // Reducer function to set user data
    setUser: (state, action) => {
      // Update localStorage with the provided user data
      localStorage.setItem("user", JSON.stringify(action.payload));
      // Update the user state in the Redux store
      state.user = action.payload;
    },
    setUserPermission: (state, action) => {
      localStorage.setItem("role", JSON.stringify(action.payload));
      state.role = action.payload;
    },
  },
});

// Exporting the reducer function generated by createSlice
export default userSlice.reducer;

// Exporting the setUser action creator for dispatching actions to set user data
export const { setUser, setUserPermission } = userSlice.actions;

// Selector function to retrieve the current user from the Redux state
export const currentUser = (state: any) => state.userState.user;
export const currentUserPermissions = (state: any) => state.userState.role;
