/*************************************************
 * Hot Deck
 * @exports
 * customFetchBase.tsx
 * Created by venugopal on 30/04/2024
 * Copyright © 2023 Hot Deck. All rights reserved.
 *************************************************/

// Import necessary modules
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { Mutex } from "async-mutex";
import { msalInstance } from "../../index";

// Define base URL for API requests
const baseUrl = process.env.REACT_APP_ADMIN_API_BASE_URL;

// Create a mutex to manage concurrent access
const mutex = new Mutex();

// Define base query function with authorization header logic
const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers) => {
    // Retrieve account and set authorization header with ID token
    const account = msalInstance.getAllAccounts()[0];
    if (account) {
      headers.set("authorization", `Bearer ${account.idToken}`);
    }
    return headers;
  },
});

// Custom fetch function to intercept and handle requests
const customFetchBase = async (args: any, api: any, extraOptions: any) => {
  // Wait for mutex to unlock
  await mutex.waitForUnlock();

  // Make the request using baseQuery
  let result = await baseQuery(args, api, extraOptions);

  // If request returns Unauthorized (401)
  if (result.error?.status === 401) {
    // If mutex is not locked, acquire it
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const account = msalInstance.getAllAccounts()[0];

        if (account) {
          try {
            // Try to refresh token silently
            await msalInstance.acquireTokenSilent({
              account: account,
              scopes: ["openid", "profile"], // Specify the scopes you want to refresh
            });

            // Retry request with updated token
            result = await baseQuery(args, api, extraOptions);
          } catch (error) {
            // If silent token acquisition fails with interaction_required, prompt the user to login interactively
            if (
              error instanceof Error &&
              error.message.includes("interaction_required")
            ) {
              localStorage.clear();
              msalInstance.logoutRedirect({
                postLogoutRedirectUri: "/",
              });
            } else {
              console.error("Token refresh failed:", error);
            }
          }
        }
      } finally {
        // Release mutex
        release();
      }
    } else {
      // If mutex is locked, wait for it to unlock and retry request
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
      // If still unauthorized, clear local storage and logout
      if (result.error?.status === 401) {
        localStorage.clear();
        msalInstance.logoutRedirect({
          postLogoutRedirectUri: "/",
        });
      }
    }
  }

  // Return the result
  return result;
};

// Export custom fetch function
export default customFetchBase;
