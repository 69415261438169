/*************************************************
 * HOT DECK
 * @exports
 * index.ts
 * Created by santhosh on 14/05/2024
 * Copyright © 2023 HotDeck. All rights reserved.
 *************************************************/

// Import necessary modules
import { createApi } from "@reduxjs/toolkit/query/react";
import CustomFetchBase from "./CustomFetchBase";
// Create an API using Redux Toolkit's createApi function
export const profileApi = createApi({
  // Specify a reducer path for the API
  reducerPath: "profileApi",
  // Define a base query using CustomFetchBase
  baseQuery: CustomFetchBase,
  // Define endpoints for the API
  endpoints: (builder) => ({
    // Define the loginUser endpoint as a query
    getUserProfile: builder.mutation({
      query: () => ({
        url: `user/getUserProfile`,
        method: "GET",
      }),
    }),
    updateUserProfile: builder.mutation({
      query: (body) => ({
        url: `user/updateUserProfile`,
        method: "POST",
        body,
      })
    }),
  }),
});

// Export the loginUser query hook from authApi
export const { useGetUserProfileMutation,useUpdateUserProfileMutation } = profileApi;
