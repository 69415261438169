import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import useModuleAccess from '../hooks/useModuleAccess';

import { CRUD_ACTIONS, PERMISSION } from '../constants/constants';
interface ModuleGuardProps {
    children: React.ReactNode;
    page: string;
    moduleName: string;
}

const ModuleGuard: React.FC<ModuleGuardProps> = ({ children, page, moduleName }) => {


    const { userPermission } = useModuleAccess(moduleName);
    const [moduleAccess, setModuleAccess] = useState<any>();


    useEffect(() => {
        const getPermission = () => {
            let flag = PERMISSION.DISABLE_ACCESS;
            userPermission?.forEach((data: any) => {
                if (page === CRUD_ACTIONS.READ) {
                    flag = data?.permissions?.View;
                }
                if (page === CRUD_ACTIONS.CREATE) {
                    flag = data?.permissions?.Create;
                }
                if (page === CRUD_ACTIONS.UPDATE) {
                    flag = data?.permissions?.Edit;
                }
            });
            return flag;
        };

        const getFlag = getPermission();
        setModuleAccess(getFlag);
    }, [page, children, userPermission]);
    if (moduleAccess === PERMISSION.DISABLE_ACCESS) {
        return <Navigate to={'/dashboard'} />;
    } else {
        return <>{children}</>;
    }
};

ModuleGuard.propTypes = {
    children: PropTypes.node.isRequired,
    page: PropTypes.string.isRequired,
    moduleName: PropTypes.string.isRequired,
};

export default ModuleGuard;
