import { useMemo } from "react";
import { useSelector } from "react-redux";
import { currentUserPermissions } from "../redux/slice/userSlice";

export default function useModuleAccess(moduleName: any) {
  const data = useSelector(currentUserPermissions);
  let userPermission = data?.filter(
    (name: any) => name.moduleName === moduleName
  );
  return useMemo(() => ({ userPermission }), [userPermission]);
}
