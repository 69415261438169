/*************************************************
 * Hot deck
 * @exports
 * index.ts
 * Created by venugopal on 05/04/2024
 * Copyright © 2024 Hot Deck. All rights reserved.
 *************************************************/

import { lazy, Suspense } from "react";

import { createBrowserRouter, Navigate } from "react-router-dom";
import Loading from "../components/custom/Loading";
import MainLayout from "../components/pages/layouts/MainLayout";
import ModuleGuard from "../guards/ModuleGuard";
import { CRUD_ACTIONS, MODULE_NAME } from "../constants/constants";
import { UserProfileProvider } from "../components/context/UserProfileContext";

// import { Store } from "../redux/Store";
// import { roleApi } from "../redux/services/RoleApi";

const Loadable = (Component: any) => (props: any) => {
  return (
    <Suspense
      fallback={
        <div>
          <Loading />
        </div>
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

// Import components

const NotFound = Loadable(lazy(() => import("../components/pages/Page404")));
const Dashboard = Loadable(lazy(() => import("../components/pages/Dashboard")));
const ThemesList = Loadable(
  lazy(() => import("../components/pages/themes/List"))
);
const CreateThemes = Loadable(
  lazy(() => import("../components/pages/themes/Create"))
);

const RolesAndPermissions = Loadable(
  lazy(() => import("../components/pages/rbac/RolesAndPermissions"))
);
const UserProfile = Loadable(lazy(() => import("../components/pages/Profile")));

const UserManagementList = Loadable(
  lazy(() => import("../components/pages/usermanagement/List"))
);
const CreateUsers = Loadable(
  lazy(() => import("../components/pages/usermanagement/Create"))
);
const EditUsers = Loadable(
  lazy(() => import("../components/pages/usermanagement/Edit"))
);
const EditThemes = Loadable(
  lazy(() => import("../components/pages/themes/Edit"))
);
const ViewThemes = Loadable(
  lazy(() => import("../components/pages/themes/View"))
);
const DeckList = Loadable(lazy(() => import("../components/pages/deck/List")));

const CreateDecks = Loadable(
  lazy(() => import("../components/pages/deck/Create"))
);
const EditDecks = Loadable(lazy(() => import("../components/pages/deck/Edit")));
const ChallengesList = Loadable(
  lazy(() => import("../components/pages/challenges/List"))
);
const CreateChallenges = Loadable(
  lazy(() => import("../components/pages/challenges/Create"))
);
const EditChallenges = Loadable(
  lazy(() => import("../components/pages/challenges/Edit"))
);
const ViewDecks = Loadable(lazy(() => import("../components/pages/deck/View")));
const CustomerList = Loadable(
  lazy(() => import("../components/pages/Customers/List"))
);
const AssociatedDeckList = Loadable(
  lazy(() => import("../components/pages/Customers/AssociatedDeckList"))
);
const ReferredCustomerList = Loadable(
  lazy(() => import("../components/pages/Customers/ReferredCustomerList"))
);
const CloneDeck = Loadable(
  lazy(() => import("../components/pages/deck/CloneDeck"))
);
const ResponseList = Loadable(
  lazy(() => import("../components/pages/challenges/ChallengeResponse"))
);

export const router = createBrowserRouter([
  {
    path: "/",
    element: <UserProfileProvider><MainLayout /></UserProfileProvider>,
    // loader: async () => {
    //   const getPages = Store.dispatch(
    //     roleApi.endpoints.getPages.initiate("")
    //   );
    //   const { data = [] } = await getPages.unwrap();
    //   return data;
    // },
    children: [
      {
        path: "/dashboard",
        element: (
          <ModuleGuard
            page={CRUD_ACTIONS.READ}
            moduleName={MODULE_NAME.DASHBOARD}
          >
            <Dashboard />
          </ModuleGuard>
        ),
      },
      {
        path: "/themes",
        element: (
          <ModuleGuard page={CRUD_ACTIONS.READ} moduleName={MODULE_NAME.THEMES}>
            <ThemesList />
          </ModuleGuard>
        ),
      },
      {
        path: "/themes/createthemes",
        element: (
          <ModuleGuard
            page={CRUD_ACTIONS.CREATE}
            moduleName={MODULE_NAME.THEMES}
          >
            <CreateThemes />
          </ModuleGuard>
        ),
      },
      {
        path: "/themes/editthemes/:id",
        element: (
          <ModuleGuard
            page={CRUD_ACTIONS.UPDATE}
            moduleName={MODULE_NAME.THEMES}
          >
            <EditThemes />
          </ModuleGuard>
        ),
      },
      {
        path: "/themes/viewthemes/:id",
        element: <ViewThemes />,
      },
      {
        path: "/roles-and-permissions",
        element: (
          <ModuleGuard
            page={CRUD_ACTIONS.READ}
            moduleName={MODULE_NAME.ROLEANDPERMISSIONS}
          >
            <RolesAndPermissions />
          </ModuleGuard>
        ),
      },
      {
        path: "/profile",
        element: <UserProfile />,
      },
      {
        path: "/user-management",
        element: (
          <ModuleGuard
            page={CRUD_ACTIONS.READ}
            moduleName={MODULE_NAME.USERMANAGEMENT}
          >
            <UserManagementList />
          </ModuleGuard>
        ),
      },
      {
        path: "/user-management/createuser",
        element: (
          <ModuleGuard
            page={CRUD_ACTIONS.CREATE}
            moduleName={MODULE_NAME.USERMANAGEMENT}
          >
            <CreateUsers />
          </ModuleGuard>
        ),
      },
      {
        path: "/user-management/edituser/:id",
        element: (
          <ModuleGuard
            page={CRUD_ACTIONS.UPDATE}
            moduleName={MODULE_NAME.USERMANAGEMENT}
          >
            <EditUsers />
          </ModuleGuard>
        ),
      },
      {
        path: "/decks",
        element: (
          <ModuleGuard page={CRUD_ACTIONS.READ} moduleName={MODULE_NAME.DECKS}>
            <DeckList />
          </ModuleGuard>
        ),
      },
      {
        path: "/decks/createdecks",
        element: (
          <ModuleGuard
            page={CRUD_ACTIONS.CREATE}
            moduleName={MODULE_NAME.DECKS}
          >
            <CreateDecks />
          </ModuleGuard>
        ),
      },
      {
        path: "/decks/editdecks/:id",
        element: (
          <ModuleGuard
            page={CRUD_ACTIONS.UPDATE}
            moduleName={MODULE_NAME.DECKS}
          >
            <EditDecks />
          </ModuleGuard>
        ),
      },
      {
        path: "/challenges",
        element: (
          <ModuleGuard
            page={CRUD_ACTIONS.READ}
            moduleName={MODULE_NAME.CHALLENGES}
          >
            <ChallengesList />
          </ModuleGuard>
        ),
      },
      {
        path: "/challenges/createchallenges",
        element: (
          <ModuleGuard
            page={CRUD_ACTIONS.CREATE}
            moduleName={MODULE_NAME.CHALLENGES}
          >
            <CreateChallenges />
          </ModuleGuard>
        ),
      },
      {
        path: "/challenges/editchallenges/:id",
        element: (
          <ModuleGuard
            page={CRUD_ACTIONS.UPDATE}
            moduleName={MODULE_NAME.DECKS}
          >
            <EditChallenges />
          </ModuleGuard>
        ),
      },

      {
        path: "/decks/viewdecks/:id",
        element: (
          <ModuleGuard
            page={CRUD_ACTIONS.UPDATE}
            moduleName={MODULE_NAME.DECKS}
          >
            <ViewDecks />
          </ModuleGuard>
        ),
      },

      {
        path: "/",
        element: <Navigate to="/dashboard" />,
      },
      {
        path: "/customers",
        element: (
          <ModuleGuard
            page={CRUD_ACTIONS.READ}
            moduleName={MODULE_NAME.CUSTOMERS}
          >
            <CustomerList />
          </ModuleGuard>
        ),
      },
      {
        path: "/customers/associatedDeck/:id",
        element: (
          <ModuleGuard
            page={CRUD_ACTIONS.READ}
            moduleName={MODULE_NAME.CUSTOMERS}
          >
            <AssociatedDeckList />
          </ModuleGuard>
        ),
      },
      {
        path: "/customers/referredList/:id",
        element: (
          <ModuleGuard
            page={CRUD_ACTIONS.READ}
            moduleName={MODULE_NAME.CUSTOMERS}
          >
            <ReferredCustomerList />
          </ModuleGuard>
        ),
      },
      {
        path: "/deck/clonedeck/:id",
        element: (
          <ModuleGuard
            page={CRUD_ACTIONS.CREATE}
            moduleName={MODULE_NAME.DECKS}
          >
            <CloneDeck />
          </ModuleGuard>
        ),
      },
      {
        path: "/challenges/response/:id",
        element: (
          <ModuleGuard
            page={CRUD_ACTIONS.READ}
            moduleName={MODULE_NAME.CHALLENGES}
          >
            <ResponseList />
          </ModuleGuard>
        ),
      },
    ],
  },
  {
    path: "*",
    children: [
      { path: "404", element: <NotFound /> },
      { path: "*", element: <Navigate to="/404" replace /> },
    ],
  },
]);
