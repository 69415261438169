/*************************************************
 * HOT DECK
 * @exports
 * index.ts
 * Created by venugopal on 05/04/2024
 * Copyright © 2023 HotDeck. All rights reserved.
 *************************************************/

// Import necessary modules
import { createApi } from "@reduxjs/toolkit/query/react";
import CustomFetchBase from "./CustomFetchBase";
import { setUserPermission } from "../slice/userSlice";
// Create an API using Redux Toolkit's createApi function
export const roleApi = createApi({
  // Specify a reducer path for the API
  reducerPath: "roleApi",
  // Define a base query using CustomFetchBase
  baseQuery: CustomFetchBase,
  // Define endpoints for the API
  endpoints: (builder) => ({
    // Define the loginUser endpoint as a query
    getRolePermissionByRole: builder.query({
      // Specify the query function
      query: ({ roleId = "roleId" }) => ({
        // Specify the URL for the request
        url: `/user/getRolePermissionByRole?roleId=${roleId}`,
        // Specify the HTTP method for the request
        method: "GET",
      }),
    }),

    getPages: builder.mutation({
      query: () => ({
        url: `/user/getPages`,
        method: "GET",
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUserPermission(data.data));
        } catch (error) {}
      },
    }),

    mapRolePermissionByRole: builder.mutation({
      query: (body) => ({
        url: `/user/mapRolePermissionByRole`,
        method: "POST",
        body: body,
      }),
    }),

    getRolePermissionMaster: builder.query({
      // Specify the query function
      query: () => ({
        // Specify the URL for the request
        url: `/user/getRolePermissionMaster`,
        // Specify the HTTP method for the request
        method: "GET",
      }),
    }),
    getRoleAccess: builder.query({
      query: () => ({
        url: `/user/getRoles`,
        method: "GET",
      }),
    }),
  }),
});

// Export the loginUser query hook from roleApi
export const {
  useLazyGetRolePermissionByRoleQuery,
  useGetPagesMutation,
  useMapRolePermissionByRoleMutation,
  useLazyGetRolePermissionMasterQuery,
  useLazyGetRoleAccessQuery,
} = roleApi;
