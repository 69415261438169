/*************************************************
 * HOT DECK
 * @exports
 * ChallengesApi.ts
 * Created by venugopal on 05/04/2024
 * Copyright © 2023 HotDeck. All rights reserved.
 *************************************************/

// Import necessary modules
import { createApi } from "@reduxjs/toolkit/query/react";
import CustomFetchBase from "./CustomFetchBase";
// Create an API using Redux Toolkit's challengesApi function
export const challengesApi = createApi({
  // Specify a reducer path for the API
  reducerPath: "challengesApi",
  // Define a base query using CustomFetchBase
  baseQuery: CustomFetchBase,
  // Define endpoints for the API
  endpoints: (builder) => ({
    getChallenges: builder.mutation({
      query: (body) => ({
        url: `/deckcreation/getChallenges`,
        method: "POST",
        body: body,
      }),
    }),
    addChallenge: builder.mutation({
      query: (body) => ({
        url: `/deckcreation/addChallenge`,
        method: "POST",
        body: body,
      }),
    }),
    getAllThemes: builder.mutation({
      query: (body) => ({
        url: `/deckcreation/getAllThemes`,
        method: "POST",
        body: body,
      }),
    }),
    getAllTags: builder.mutation({
      query: (body) => ({
        url: `/deckcreation/getAllTags`,
        method: "POST",
        body: body,
      }),
    }),
    getChallengeById: builder.query({
      // Specify the query function
      query: ({ id = "id" }) => ({
        // Specify the URL for the request
        url: `/deckcreation/getChallengeById?id=${id}`,
        // Specify the HTTP method for the request
        method: "GET",
      }),
    }),

    updateChallenge: builder.mutation({
      query: (body) => ({
        url: `/deckcreation/updateChallenge`,
        method: "POST",
        body: body,
      }),
    }),

    getChallengeResponseById: builder.mutation({
      query: (body) => ({
        url: `/deckcreation/getChallengeResponseById`,
        method: "POST",
        body: body,
      }),
    }),
  }),
});

// Export the loginUser query hook from challengesApi
export const {
  useGetChallengesMutation,
 useAddChallengeMutation,
  useGetAllThemesMutation,
  useGetAllTagsMutation,
  useLazyGetChallengeByIdQuery,
  useUpdateChallengeMutation,
  useGetChallengeResponseByIdMutation
} = challengesApi;
