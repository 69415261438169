/*************************************************
 * HotDeck
 * @exports
 * Header.tsx
 * Created by venugopal on 14/04/2024
 * Copyright © 2024 HotDeck. All rights reserved.
 *************************************************/

//import
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sidebarlogo from "../../../assets/sidelogo.png";
import { Icon } from "@iconify/react";
import { useGetPagesMutation } from "../../../redux/services/RoleApi";
import { Menu } from "lucide-react";
import { Button } from "../../ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../ui/dropdown-menu";
import { Sheet, SheetClose, SheetContent, SheetTrigger } from "../../ui/sheet";
import { msalInstance } from "../../../index";
import { passwordChangeRequest } from "../../../authConfig";
import { useGetUserProfileMutation } from "../../../redux/services/ProfileApi";
import NoImage from "../../../assets/no-image.webp";
import { UserProfileContext } from "../../context/UserProfileContext";
interface Permission {
  Create: number;
  View: number;
  Edit: number;
  Delete: number;
}
type MenuItem = {
  moduleName: string;
  pathUrl: string;
  iconUrl: JSX.Element;
  pageName: string;
  permissions: Permission;
};

const Header = () => {
  const [pages, setPages] = useState<MenuItem[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [getPages, { isLoading, isError, isSuccess, data }] =
    useGetPagesMutation();
  const [getUserProfile, userProfileData] = useGetUserProfileMutation();
  const { userProfile, setUserProfile } = useContext(UserProfileContext);

  useEffect(() => {
    getPages("");
    getUserProfile("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (userProfileData?.isSuccess) {
      setUserProfile({
        profileImageUrl: userProfileData?.data?.data?.profileImageUrl,
        name: userProfileData?.data?.data?.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfileData]);
  useEffect(() => {
    if (isSuccess) {
      setPages(data.data);
    }
    if (isError) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleLogout = () => {
    setOpen(false);
    localStorage.clear();
    msalInstance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };
  const handlePasswordChange = () => {
    //Change Password using azure ad b2c
    setOpen(false);
    msalInstance.loginRedirect(passwordChangeRequest).catch((error) => {
      console.error("Password reset failed:", error);
    });
  };
  return (
    <>
      <header className="flex h-14 items-center gap-4 border-b  px-4 lg:h-[60px] lg:px-6">
        <Sheet>
          <SheetTrigger asChild>
            <Button
              variant="outline"
              size="icon"
              className="shrink-0 md:hidden"
            >
              <Menu className="w-5 h-5" />
              <span className="sr-only">Toggle navigation menu</span>
            </Button>
          </SheetTrigger>
          <SheetContent side="left" className="flex flex-col">
            <nav className="grid gap-2 text-lg font-medium">
              <div className="flex h-14 items-center mt-6 justify-center   px-4 lg:h-[60px] lg:px-6">
                <img src={sidebarlogo} alt={"sidebarlogo"}></img>
              </div>
              {pages.length > 0 &&
                pages.map((menuItem, index) =>
                  menuItem.permissions.View === 1 ? (
                    <SheetClose asChild key={index}>
                      <Link
                        key={index}
                        to={menuItem.pathUrl}
                        className="mx-[-0.65rem] flex items-center mt-4 gap-4 rounded-xl px-3 py-2 text-muted-foreground hover:text-foreground"
                      >
                        <span className="">
                          {menuItem.moduleName === "Dashboard" ? (
                            <Icon
                              icon="mingcute:dashboard-2-line"
                              style={{
                                color: "#9A9A97",
                                width: "18",
                                height: "18",
                              }}
                            />
                          ) : menuItem.moduleName === "Decks" ? (
                            <Icon
                              icon="gg:notes"
                              style={{
                                color: "#9A9A97",
                                width: "18",
                                height: "18",
                              }}
                            />
                          ) : menuItem.moduleName === "Challenges" ? (
                            <Icon
                              icon="simple-line-icons:question"
                              style={{
                                color: "#9A9A97",
                                width: "18",
                                height: "18",
                              }}
                            />
                          ) : menuItem.moduleName === "Customers" ? (
                            <Icon
                              icon="carbon:user-multiple"
                              style={{
                                color: "#9A9A97",
                                width: "18",
                                height: "18",
                              }}
                            />
                          ) : menuItem.moduleName === "User Management" ? (
                            <Icon
                              icon="mage:filter"
                              style={{
                                color: "#9A9A97",
                                width: "18",
                                height: "18",
                              }}
                            />
                          ) : menuItem.moduleName ===
                            "Roles and Permissions" ? (
                            <Icon
                              icon="system-uicons:clipboard-notes"
                              style={{
                                color: "#9A9A97",
                                width: "18",
                                height: "18",
                              }}
                            />
                          ) : menuItem.moduleName === "Themes" ? (
                            <Icon
                              icon="gridicons:themes"
                              style={{
                                color: "#9A9A97",
                                width: "18",
                                height: "18",
                              }}
                            />
                          ) : null}
                        </span>
                        <span>{menuItem.moduleName}</span>
                      </Link>
                    </SheetClose>
                  ) : null
                )}
            </nav>
          </SheetContent>
        </Sheet>
        <div className="flex-1 w-full"></div>
        <DropdownMenu open={open} onOpenChange={setOpen}>
          <DropdownMenuTrigger asChild>
            <div
              className="flex p-1 space-x-1 font-semibold rounded-sm cursor-pointer"
              onClick={() => setOpen(true)}
            >
              <Button variant="secondary" size="icon" className="rounded-full ">
                <img
                  src={userProfile?.profileImageUrl|| NoImage}
                  alt={"profileLogo"}
                  style={{
                    display: isLoading ? "none" : "block",
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                  }}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = NoImage;
                  }}
                ></img>
              </Button>
              <p className="mt-2">{userProfile?.name} </p>{" "}
              <Icon
                icon="bitcoin-icons:caret-down-filled"
                style={{
                  color: "#000000",
                  width: "16",
                  height: "16",
                }}
                className="mt-3"
              />
            </div>
          </DropdownMenuTrigger>

          <DropdownMenuContent align="end">
            {/* <DropdownMenuSeparator /> */}
            <DropdownMenuItem>
              <Link to={"/profile"} onClick={() => setOpen(false)}>
                <div className="flex space-x-2 ">
                  {" "}
                  <Icon
                    icon="iconamoon:profile-fill"
                    style={{
                      color: "#D51B0C",
                      width: "18",
                      height: "18",
                    }}
                  />
                  <p className="transition-all rounded-lg text-muted-foreground hover:text-primary">
                    My Profile{" "}
                  </p>
                </div>
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem>
              {" "}
              <div
                className="flex space-x-2 cursor-pointer"
                onClick={() => handlePasswordChange()}
              >
                {" "}
                <Icon
                  icon="mynaui:lock-password"
                  style={{
                    color: "#D51B0C",
                    width: "18",
                    height: "18",
                  }}
                />
                <p className="transition-all rounded-lg text-muted-foreground hover:text-primary">
                  Change Password{" "}
                </p>
              </div>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem>
              <div
                className="flex space-x-2 cursor-pointer "
                onClick={() => {
                  handleLogout();
                }}
              >
                {" "}
                <Icon
                  icon="majesticons:logout-half-circle"
                  style={{
                    color: "#D51B0C",
                    width: "18",
                    height: "18",
                  }}
                />
                <p className="transition-all rounded-lg text-muted-foreground hover:text-primary">
                  Logout{" "}
                </p>
              </div>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </header>
    </>
  );
};
export default Header;
